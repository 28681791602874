import { makeAutoObservable, runInAction } from 'mobx'
import { groupApi } from '@/api/group'
import {
  Chapters,
  GetHomeworkParams,
  HomeworkFilterStatus,
  IExamInfo,
  IExamResult,
  IGetGroupChapters,
  IGroup,
  IGroupChapterDeadline,
  IGroupChapters,
  IGroupData,
  IGroupInfoParams,
  IGroupLesson,
  IGroupLessonParams,
  IGroupLessonVideoNameResponse,
  IGroupParams,
  IGroupStaffDetail,
  IGroupVideoLesson,
  IGroupVideoLessons,
  IHomeworkChat,
  ILessonVideos,
  ISchedules,
  HomeworkSubmission,
} from '@/api/group/types'
import { addAxiosErrorNotification } from '@/components/Notifications'
import { UNGRADED_LESSON_ID } from '@/constants'
import { EditHomeworkParams, HomeworkSubmissionParams, IGetHomeworkUploadUrl } from './types'
class GroupStore {
  activeGroups: IGroupData[] = []
  activeTotal = 0
  inActiveGroups: IGroupData[] = []
  inActiveTotal = 0
  groupInfoLessons: IGroupLesson[] = []
  groupInfo: IGroup | null = null
  page = 1
  perPage = 10
  isOpenVideoModal = false
  infoTotal = 0
  groupVideos: Chapters | null = null
  selectVideos: ILessonVideos | null = null
  groupLesson: IGroupVideoLessons | null = null
  activeLesson: IGroupVideoLesson | null = null
  homeworkChats: IHomeworkChat | null = null
  staffDetail: IGroupStaffDetail[] = []
  isOpenStaffDetail = false
  scheduleItemsModal = false
  scheduleItem: ISchedules | null = null
  groupName: null | string = null
  groupChapters: IGroupChapters | null = null
  groupHomeworkDeadlineSort: IGroupChapterDeadline | null = null
  groupChapterHomeworkStatus: HomeworkFilterStatus | string | null = ''
  groupChapterCreatedAt: IGroupChapterDeadline | null = null
  groupLessonVideoNames: IGroupLessonVideoNameResponse[] | null = null
  groupInfoModal = false
  singleGroupInfo: IGroup | null = null
  chapterInfoModal = false
  selectedChapterInfo: Chapters | null = null
  canEdit = false
  examResults: IExamResult[] | null = null
  isOpenGradeModal = false
  lessonId: number | null = null
  activeLessonAccordion: number | null = null
  isLessonsPage = false
  editableSubmissions: HomeworkSubmission[] | null = null
  lessonName = ''

  constructor() {
    makeAutoObservable(this)
  }

  setLessonName = (name: string) => {
    this.lessonName = name
  }

  setCanEdit = (params: boolean) => {
    this.canEdit = params
  }

  setEditableSubmissions = (params: HomeworkSubmission[] | null) => {
    this.editableSubmissions = params
  }

  setExamResult = (params: IExamResult[] | null) => {
    this.examResults = params
  }

  setSelectedChapterInfo = (params: Chapters | null) => {
    this.selectedChapterInfo = params
  }

  setChapterInfoModal = (params: boolean) => {
    this.chapterInfoModal = params
  }

  setSingleGroupInfo = (params: IGroup | null) => {
    this.singleGroupInfo = params
  }

  setGroupInfoModal = (params: boolean) => {
    this.groupInfoModal = params
  }

  setChapterHomeworkStatus = (params: string | HomeworkFilterStatus | null) => {
    this.groupChapterHomeworkStatus = params
  }

  setGroupChapterCreatedAt = (params: IGroupChapterDeadline | null) => {
    this.groupChapterCreatedAt = params
  }

  setGroupHomeworkDeadlineSort = (params: IGroupChapterDeadline | null) => {
    this.groupHomeworkDeadlineSort = params
  }

  setScheduleItem = (params: ISchedules | null) => {
    this.scheduleItem = params
  }

  setGroupName = (params: null | string) => {
    this.groupName = params
  }

  setScheduleItemsModal = (params: boolean) => {
    this.scheduleItemsModal = params
  }

  setOpenGradeModal = (value: boolean) => {
    this.isOpenGradeModal = value
  }

  setLessonId = (id: number | null) => {
    this.lessonId = id
  }

  setActiveLessonAccordion = (value: number | null) => {
    this.activeLessonAccordion = value
  }

  setIsLessonsPage = (value: boolean) => {
    this.isLessonsPage = value
  }

  getActiveGroups = (params: IGroupParams) =>
    groupApi
      .getGroups(params)
      .then((res) => {
        if (res?.success && res?.data) {
          this.setActiveGroups(res.data.groups)
          this.activeTotal = res.data.total
        }

        return res
      })
      .catch(addAxiosErrorNotification)

  getInActiveGroups = (params: IGroupParams) =>
    groupApi
      .getGroups(params)
      .then((res) => {
        if (res?.success && res?.data) {
          this.inActiveGroups = res.data.groups
          this.inActiveTotal = res.data.total
        }

        return res
      })
      .catch(addAxiosErrorNotification)

  getGroupExamResults = (params: number) =>
    groupApi
      .getExamResults(params)
      .then((res) => {
        if (res.success) {
          this.setExamResult(res.data)
        }
      })
      .catch(addAxiosErrorNotification)

  getHomeworkUrl = (params: IGetHomeworkUploadUrl) => groupApi.getHomeworkUrl(params)

  getGroupInfo = (params: IGroupInfoParams) =>
    groupApi
      .getGroupInfo(params)
      .then((res) => {
        if (res.success && res.data) {
          this.groupInfoLessons = res.data?.groupData?.lessons
          this.groupInfo = res.data?.groupData?.group
          this.infoTotal = res.data?.total
        }
      })
      .catch(addAxiosErrorNotification)

  getGroupsChapters = (params: IGetGroupChapters) =>
    groupApi
      .getGroupChapters(params)
      .then((res) => {
        if (res.success) {
          this.groupChapters = res.data
        }
      })
      .catch(addAxiosErrorNotification)

  getGroupLessons = (params: IGroupLessonParams) =>
    groupApi
      .getGroupLesson(params)
      .then((res) => {
        if (res?.success && res?.data) {
          const groupLessonData = res.data?.groupData

          this.setGroupLesson(groupLessonData)
        }

        return res
      })
      .catch(addAxiosErrorNotification)

  editHomework = (params: EditHomeworkParams) => groupApi.editHomework(params)

  getHomeworkChats = (params: GetHomeworkParams) =>
    groupApi
      .getHomeworkChat(params)
      .then((res) => {
        if (res?.success && res?.data) {
          this.setHomeworkChats(res.data?.homeworkChatsData)
        }

        return res
      })
      .catch((error) => {
        const errId = error?.response?.data?.error?.errId

        if (errId !== UNGRADED_LESSON_ID) {
          addAxiosErrorNotification(error)
        }
        this.setOpenGradeModal(errId === UNGRADED_LESSON_ID)
      })

  getExam = (params: number) =>
    groupApi
      .getExam(params)
      .then((res) => {
        if (res?.success) {
          this.setHomeworkChats({ homeworkInfo: res.data, homeworkChats: this.homeworkChats?.homeworkChats || [] })
        }
      })
      .catch(addAxiosErrorNotification)

  getExamChats = (params: number) =>
    groupApi
      .getExamChats(params)
      .then((res) => {
        if (res?.success) {
          this.setHomeworkChats({
            homeworkInfo: this.homeworkChats?.homeworkInfo || null,
            homeworkChats: res.data,
          })
        }

        return res
      })
      .catch(addAxiosErrorNotification)

  sendExam = (params: IExamInfo) => groupApi.sendExam(params)

  sendHomeworkSubmission = (params: HomeworkSubmissionParams) => groupApi.sendHomeworkSubmission(params)

  getGroupLessonVideoName = (id: number) =>
    groupApi
      .getGroupLessonVideoName(id)
      .then((res) => {
        if (res?.success && res?.data) {
          runInAction(() => {
            this.setGrouLessonVideoNames(res?.data)
          })
        }
      })
      .catch(addAxiosErrorNotification)

  setGrouLessonVideoNames = (names: IGroupLessonVideoNameResponse[] | null) => {
    this.groupLessonVideoNames = names
  }

  setActiveGroups = (groups: IGroupData[]) => {
    this.activeGroups = groups
  }

  setPage = (page: number) => {
    this.page = page
  }

  setPerPage = (perPage: number) => {
    this.perPage = perPage
  }

  setIsOpenLessonVideoModal = (isOpen: boolean) => {
    this.isOpenVideoModal = isOpen
  }

  setGroupVideos = (groupVideos: Chapters) => {
    this.groupVideos = groupVideos
  }

  setSelectVideo = (video: ILessonVideos | null) => {
    this.selectVideos = video
  }

  setGroupLesson = (lesson: IGroupVideoLessons) => {
    this.groupLesson = lesson
  }

  setActiveLesson = (activeLesson: IGroupVideoLesson) => {
    activeLesson.videos = activeLesson?.videos?.map((video: ILessonVideos, index: number) => ({ ...video, index }))
    this.activeLesson = activeLesson
  }

  setHomeworkChats = (homeworkChats: IHomeworkChat | null) => {
    this.homeworkChats = homeworkChats
  }

  setOpenStaffDetail = (isOpen: boolean) => {
    this.isOpenStaffDetail = isOpen
  }

  setStaffDetail = (staffs: IGroupStaffDetail[]) => {
    this.staffDetail = staffs
  }

  reset() {
    this.page = 1
    this.perPage = 10
    this.groupLessonVideoNames = null
    this.isOpenGradeModal = false
    this.lessonId = null
    this.activeLessonAccordion = null
    this.isLessonsPage = false
  }
}

export const groupStore = new GroupStore()
