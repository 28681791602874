import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyD1kdHqjVw6c9OF_YkprvixjlEP4SMantQ',
  authDomain: 'erpmobile-99807.firebaseapp.com',
  projectId: 'erpmobile-99807',
  storageBucket: 'erpmobile-99807.appspot.com',
  messagingSenderId: '44855863588',
  appId: '1:44855863588:web:a53d5131c8192f4f982bb1',
  measurementId: 'G-6HKXFBPBFE',
}

const app = initializeApp(firebaseConfig)

export const messaging = getMessaging(app)
